/* COLORS */

$color-primary-0: #F7EF00;	// Main Primary color */
$color-primary-1: #F9EE6B;
$color-primary-2: #DDD13E;
$color-primary-3: #9E9308;
$color-primary-4: #756C00;

$color-secondary-1-0: #FFA500;	// Main Secondary color (1) */

$color-secondary-2-0: #00529C;	// Main Secondary color (2) */
$color-secondary-2-1: #49809E;
$color-secondary-2-2: #2D6B8C;
$color-secondary-2-3: #0A4564;
$color-secondary-2-4: #03314A;

$color-white: #e5e5e5;
$color-true-white: #fff;
$color-black: #191919;
$color-true-black: #000;
$color-gray: #2a2a2a;
$color-light-gray: #CCC;

$primary-color: $color-primary-0;
$secondary-color: $color-secondary-1-0;
$alt-color: $color-secondary-2-0;
$alt-secondary-color: #009900;


/* TOP NAV */
$topbar-background: rgba(0,0,0,0);

/* VIEWPORT WIDTHS */
$mobile-width: 640px;
$desktop-width: 1024px;

/* FONTS */
$primary-font-family: 'Open Sans', sans-serif;