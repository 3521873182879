html {
    font-size: 90%;
    line-height: 1.6;
    @include tablet {
        font-size: 80%;
    }
    @include mobile {
        font-size: 70%;
    }
}

section.header-call-to-action {
    background-color: $color-black;
    text-align: center;
    padding: 50px 0;
    p {
        color: $color-true-white;
        font-size: rem-calc(30);
        span {
            color: $primary-color;
            font-weight: bold;
        }
        i {
            font-size: rem-calc(50);
        }
    }
}

section.home-features {
    position: relative;
    nav {
        position: absolute;
        width: 100%;
        top: -35px;
        ul {
            list-style: none;
            margin: 0;
            text-align: center;
            width: 80%;
            margin: 0 auto;
            @include box-shadow(0, 2px, 2px);
            &:after {
                display: block;
                content: '';
                clear: both;
            }
            li {
                display: block;
                text-align: center;
                float: left;
                width:33.33%;
                margin-bottom: 0;
                @include mobile() {
                    height: 108px;   
                }
                a {
                    display: block;
                    padding: 10px;
                    border: 2px solid rgba(255,255,255,0);
                    color: $color-true-white;
                    font-weight: bold;
                    @include mobile() {
                        height: 100%;   
                    }
                    @include transition(all 400ms ease-in-out);
                    &:hover {
                        border-color: rgba(255,255,255,1);
                        @include transition(all 400ms ease-in-out);
                    }
                    &.phone-systems-voicemail-btn {
                        //@include linear-gradient($secondary-color, darken($secondary-color, 10%));
                        background-color: $alt-color;
                        &:hover, &.active {
                            //@include linear-gradient(lighten($secondary-color, 5%), $secondary-color);
                            background-color: lighten($alt-color, 5%);
                        }
                    }
                    &.network-computer-support-btn {
                        // @include linear-gradient($primary-color, darken($primary-color, 10%));
                        background-color: $alt-color;
                        &:hover, &.active {
                            // @include linear-gradient(lighten($primary-color, 5%), $primary-color);
                            background-color: lighten($alt-color, 5%);
                        }
                    }
                    &.voice-data-cabling-btn {
                        // @include linear-gradient($alt-color, darken($alt-color, 10%));
                        background-color: $alt-color;
                        &:hover, &.active {
                            // @include linear-gradient(lighten($alt-color, 5%), $alt-color);
                            background-color: lighten($alt-color, 5%);
                        }
                    }
                }
            }
        }
    }
    .feature-slide {
        padding: 100px 0;
        color: $color-black;
        text-align: center;
        &.ng-show {
            -webkit-animation: fadeInRight 0.5s;
            animation: fadeInRight 0.5s;
        }
        a {
            @include mobile {
                margin-bottom: rem-calc(20);
            }
        }
        &.phone-systems-voicemail {
            background: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url('/images/phone-systems-bg.jpg') no-repeat center left/cover;
            // @include linear-gradient($secondary-color, darken($secondary-color, 10%));
        }
        &.network-computer-support {
            background: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url('/images/network-bg.jpg') no-repeat top left/cover;
            // background: linear-gradient(rgba(0, 82, 156, 0.8), darken(rgba(0, 82, 156, 0.8), 10%)), url('/images/network-bg.jpg') no-repeat top left/cover;
            
        }
        &.voice-data-cabling {
            background: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url('/images/voice-data-cabling-bg.jpg') no-repeat top left/cover;
            // background: linear-gradient(rgba(239, 90, 41, 0.8), darken(rgba(239, 90, 41, 0.8), 10%)), url('/images/voice-data-cabling-bg.jpg') no-repeat top left/cover;
            
        }
    }
}

section.about {
    padding: 100px 0;
    background-color: $color-white;
    @include mobile {
        text-align: center;
    }
    section.testimonials {
        position: relative;
        display: block;
        a.testimonial-nav {
            font-size: rem-calc(50);
            color: $color-black;
            display: block;
            position: absolute;
            top:50%;
            margin-top: -25px;
            z-index: 1;
            &.previous {
                left: 0;
            }
            &.next {
                right: 0;
            }
        }
        .testimonial-container {
            text-align: center;
            blockquote {
                display: block;
                height: auto;
                border: none;
                font-style: italic;
                font-family: "Times New Roman", serif;
                cite {
                    font-family: "Arial", sans-serif;
                    font-style: normal;
                }
            }
        }
    }
}

section.footer-call-to-action {
    padding: 50px 0;
    background-color: $alt-color;
    color: $color-true-white;
    text-align: center;
    p {
        color: $color-true-white;
        font-size: rem-calc(30);
    }
}

article.service-list {
    h2 {
        text-align: center;
    }
    section.service-feature {
        padding: rem-calc(100) 0;
        @include mobile() {
            text-align: center;
            ul {
                list-style: none;
            }
        }
        &:last-child {
            padding-bottom: rem-calc(100);
        }
        &.phone {
            background: -webkit-linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/phone-systems-features-bg.jpg') no-repeat top left/cover fixed;
            background: -o-linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/phone-systems-features-bg.jpg') no-repeat top left/cover fixed;
            background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/phone-systems-features-bg.jpg') no-repeat top left/cover fixed;
        }
        &.network-solutions {
            background: -webkit-linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/network-support-bg.jpg') no-repeat top left/cover fixed;
            background: -o-linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/network-support-bg.jpg') no-repeat top left/cover fixed;
            background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/network-support-bg.jpg') no-repeat top left/cover fixed;
        }
        &.voice-data-cabling {
            background: -webkit-linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/voice-data-service-bg.jpg') no-repeat 0 75%/cover fixed;
            background: -o-linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/voice-data-service-bg.jpg') no-repeat 0 75%/cover fixed;
            background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url('/images/voice-data-service-bg.jpg') no-repeat 0 75%/cover fixed;
        }
        ul {
            li {
                font-weight: bold;
            }
        }
        .feature-box {
            @include mobile() {
                margin-bottom: 25px;
            }
            // &:last-child {
            //     @include mobile {
            //         margin-bottom: 0;
            //     }
            // }
            header {
                text-align: center;
                margin-bottom: rem-calc(15);
                i {
                    color: $alt-color;
                    font-size: rem-calc(50);
                    margin-bottom: rem-calc(20);
                    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
                }
            }
            ul {
                @include mobile() {
                    list-style: none;
                    margin: 0;
                }
                li {
                    @include mobile() {
                        text-align: center;
                    }
                }
            }        
        }
    }
}

article.contact-us-container {
    background: $color-white;
    padding: rem-calc(100) 0;
    .contact-information {
        ul {
            list-style: none;
            margin: 0;
            li {
                display: block;
                position: relative;
                padding-left: rem-calc(30);
                strong {
                    display: block;
                }
                &:before {
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    /*--adjust as necessary--*/
                    color: $color-gray;
                    font-size: 18px;
                    padding-right: 0.5em;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &.phone {
                    strong {
                        display: inline;
                    }
                    &:before {
                        content: "\f095";
                    }
                }
                &.fax {
                    strong {
                        display: inline;
                    }
                    &:before {
                        content: "\f1ac";
                    }
                }
                &.email {
                    &:before {
                        content: "\f0e0";
                    }
                }
                a {
                    color: $alt-color;
                    &:hover {
                        color: darken($alt-color, 10%);
                    }
                }
            }
        }
    }
    .contact-form {
        form {
            .callout {
                border: 1px solid #ff0000;
            }
            label {
                font-weight: bold;
                input, textarea, ::placeholder {
                    font-weight: normal;
                }
            }
            button {
                display: block;
                width: 100%;
                background-color: $color-gray;
                &:hover {
                    background-color: darken($color-gray, 10%);
                }
            }
        }
    }
}