.custom-button {
    display: inline-block;
    position: relative;
    border: 2px solid $color-true-white;
    color: $color-true-white;
    background-color: rgba(0,0,0,0);
    padding: 15px;
    font-size:20px;
    @include transition(color 400ms ease-in-out, background-color 400ms ease-in-out, border-color 400ms ease-in-out);
    &:hover {
        color: $color-black;
        background-color: $color-true-white;
        @include transition(color 400ms ease-in-out, background-color 400ms ease-in-out, padding 400ms ease-in-out, border-color 400ms ease-in-out);
    }
    &.primary {
        border-color: $secondary-color;
        background-color: $secondary-color;
        &:hover {
            color: $color-true-white;
            border-color: darken($secondary-color, 5%);
            background-color: darken($secondary-color, 5%);
        }
    }
    &.black {
        border-color: $color-black;
        background-color: rgba(0,0,0,0);
        color: $color-black;
        &:hover {
            color: $color-true-white;
            background-color: $color-black;
        }
    }
}

.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  padding-right: 2.2em;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.hvr-icon-forward:before {
  content: "\f101";
  position: absolute;
  right: 1em;
  padding: 0 1px;
  font-family: FontAwesome;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-forward:hover:before, .hvr-icon-forward:focus:before, .hvr-icon-forward:active:before {
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
}
