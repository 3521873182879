h1, h2, h3, h4, h5, h6, p, ul li, a {
    font-family: $primary-font-family;
}

h1, h2, h3 {
    //text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    font-weight: 600;
}

h1 {
    font-size: rem-calc(56);
}

h2 {
    font-size: rem-calc(48);
    margin-bottom: rem-calc(48);
    font-weight: 300;
}

h3 {
    font-size: rem-calc(40);
    margin-bottom: rem-calc(40);
    font-weight: 300;
}

h4 {
    font-size: rem-calc(32)
}

h5 {
    font-size: rem-calc(24);
}

h6 {
    font-size: rem-calc(20);
}

p, ul li {
    font-size: rem-calc(20);
    margin-bottom: rem-calc(20);
}

ul {
    margin-bottom: rem-calc(20);
}
