footer.default-footer {
	padding: 50px 0;
	background-color: $color-black;
	color: $color-true-white;
	h4 {
		color: $primary-color;
        font-weight: 400;
	}
	p {
		margin: 0;
	}
	ul {
		list-style: none;
		margin: 0 0 10px;
		li {
			color: $color-true-white;
            margin-bottom: 0;
			a {
				color: $color-true-white;
				@include transition(color 400ms ease-in-out);
				&:hover {
					color: $primary-color;
				}
			}
		}
		&.footer-social {
			&:after {
				display: block;
				content: '';
				clear: both;
			}
			li {
				display:block;
				float: left;
				margin-right: 10px;
				&:last-child {
					margin-right: 0;
				}
				a {
					font-size: 30px;
				}
			}
		}
	}
	.copyright {
		@include mobile {
			text-align: center;
		}
	}
}
