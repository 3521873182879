.hero {
    width: 100%;
    padding: 200px 0;
    @include desktop {
        padding-top: 225px;
    }
    @include tablet {
        padding-top: 225px;
    }
    h2, p {
        color: $color-true-white;
    }
    h2 {
        font-family: "Arial", sans-serif;
        @include mobile {
            font-size: rem-calc(32);
        }
    }
    a {
        @include mobile {
            margin-bottom: rem-calc(20);
        }
    }
    &.home-hero {
        background: linear-gradient(rgba(42, 42, 42, 0.35), darken(rgba(42, 42, 42, 0.35), 10%)), url('/images/neccorp-phone.jpg') no-repeat top left/cover;
        p {
            color: $color-black;
        }
        h2 {
            color: $color-true-white;
            margin-bottom: rem-calc(10);
            span {
                color: $color-light-gray;
                font-weight: bold;
            }
        }
        p {
            font-size: rem-calc(30);
            font-weight: bold;
            color: $alt-color;
        }
        a.custom-button {
            border-radius: rem-calc(5);
            box-shadow: none;
            color: #000;
            font-family: "Arial", sans-serif;
        }
        @include mobile {
            height: auto;
            padding: 100px 0;
            text-align: center;
        }
    }
    &.service-hero {
        text-align: center;
        @include mobile {
            padding: 100px 0;
        }
        h1, h3, ul li {
            color: $color-true-white;
        }
        h3 {
            a {
                color: inherit;
                text-decoration: underline;
                &:hover {
                    color: $primary-color;
                }
            }
        }
        ul {
            list-style: none;
            margin: 0;
        }
        &.phone {
            // background: linear-gradient(rgba(247, 239, 0, 0.8), darken(rgba(247, 239, 0, 0.8), 10%)), url('/images/phone-systems-hero-bg.jpg') no-repeat top left/cover;
            // @include linear-gradient($secondary-color, darken($secondary-color, 10%));
            background: linear-gradient(rgba(42, 42, 42, 0.8), darken(rgba(42, 42, 42, 0.8), 10%)), url('/images/phone-systems-hero-bg.jpg') no-repeat top left/cover;
        }
        &.network {
            background: linear-gradient(rgba(0, 82, 156, 0.8), darken(rgba(0, 82, 156, 0.8), 10%)), url('/images/network-support-hero-bg.jpg') no-repeat top left/cover;
            // @include linear-gradient($primary-color, darken($primary-color, 10%));            
        }
        &.voice {
            background: linear-gradient(rgba(255,165,0, 0.8), darken(rgba(255,165,0, 0.8), 10%)), url('/images/voice-data-hero-bg.jpg') no-repeat 0 75%/cover;
            // @include linear-gradient($alt-color, darken($alt-color, 10%));
        }
    }
    &.contact-us {
        text-align: center;
        @include mobile() {
            padding: 100px 0;
        }
        background: linear-gradient(rgba(42, 42, 42, 0.8), darken(rgba(42, 42, 42, 0.8), 10%)), url('/images/contact-us-hero-bg.jpg') no-repeat 0 20%/cover;
        h1 {
            color: $color-true-white;
        }
    }
}