@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,800,600);

@import "variables";
@import "mixins";
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import 'foundation';
@include foundation-everything(true);

@import "typography";
@import "buttons";
@import "header";
@import "hero";
@import "footer";
@import "styles";
