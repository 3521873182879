@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width + 1px}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin line-height($font-size) {
    line-height: $font-size * 1.618;
}

@mixin linear-gradient($color-start, $color-end) {
    background-image: -webkit-gradient(
    	linear,
    	left top,
    	left bottom,
    	color-stop(0, $color-start),
    	color-stop(1, $color-end)
    );
    background-image: -o-linear-gradient(bottom, $color-start 0%, $color-end 100%);
    background-image: -moz-linear-gradient(bottom, $color-start 0%, $color-end 100%);
    background-image: -webkit-linear-gradient(bottom, $color-start 0%, $color-end 100%);
    background-image: -ms-linear-gradient(bottom, $color-start 0%, $color-end 100%);
    background-image: linear-gradient(to bottom, $color-start 0%, $color-end 100%);
}

@mixin box-shadow($x, $y, $blur, $spread: 0, $opacity: rgba(0,0,0,0.75)) {
    -webkit-box-shadow: $x $y $blur $spread $opacity;
    -moz-box-shadow: $x $y $blur $spread $opacity;
    box-shadow: $x $y $blur $spread $opacity;
}

@mixin transition($transition-settings...) {
    -webkit-transition: $transition-settings;
    -moz-transition :$transition-settings;
    -ms-transition: $transition-settings;
    -o-transition: $transition-settings;
    transition: $transition-settings;
}
