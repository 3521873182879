header.primary-top {
    position: absolute;
    display: block;
    width: 100%;
    div.mobile-bar {
        display: none;
        position: fixed;
        width: 100%;
        z-index: 10;
        height: 50px;
        background-color: $color-black;
        padding: rem-calc(10);
        a.navi {
            display: block;
            position: absolute;
            font-size: rem-calc(30);
            left: 20px;
            top: 50%;
            margin-top: -15px;
            color: $color-true-white;
        }
        p {
            display: block;
            margin: 0;
            color: $color-true-white;
            font-weight: bold;
            a {
                display: block;
                position: relative;
            }
        }
        @include mobile() {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    nav#mobile-menu {
        display: none;
        position: fixed;
        z-index: 9999;
        top: 50px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.9);
        @include mobile() {
            display: block;
        }
        ul {
            margin: 0;
            li {
                margin: 0;
                border-bottom: 1px solid $color-black;
                a {
                    display: block;
                    padding: rem-calc(20) rem-calc(20);
                    color: $color-true-white;
                    &:hover {
                        background-color: rgba(255,255,255,0.2);
                        color: $primary-color;
                    }
                }
            }
        }
    }
    nav.main-nav {
        .row {
            padding: 10px 0;
        }
        @include mobile() {
            display: none;
        }
        .is-stuck {
            background-color: $color-white;
            box-shadow: 0 1px 3px rgba(0,0,0,0.6);
            ul li a {
                color: $color-black;
                &:hover {
                    color: $secondary-color;
                }
            }
            .nav-logo {
                display: none;
            }
            .nav-logo-scroll {
                display: block;
            }
        }
        .nav-logo {
            display: block;
            position: absolute;
            top: 5px;
        }
        .nav-logo-scroll {
            display: none;
        }
        .menu {
            float: right;
            &.voice-data-cabling {
                li {
                    a {
                        &:hover {
                            color: $color-true-black;
                        }
                    }
                    & > ul {
                        li {
                            a {
                                &:hover {
                                    color: $secondary-color;
                                }
                            }
                        }
                    }
                }
            }
            li {
                position: relative;
                &.menu-text {
                    color: $color-true-white;
                    a:hover {
                        background-color: transparent;
                    }
                }
                a {
                    color: $color-true-white;
                    font-weight: bold;
                    &:hover {
                        color: $secondary-color;
                    }
                }
                & > ul {
                    display: block;
                    position: absolute;
                    bottom: -160px;
                    left: -95px;
                    background-color: $color-gray;
                    width: 300px;
                    margin: 0;
                    list-style: none;
                    border-radius: 10px;
                    &:after {
                        bottom: 100%;
                        left: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(136, 183, 213, 0);
                        border-bottom-color: $color-gray;
                        border-width: 10px;
                        margin-left: -10px;
                    }
                    li {
                        margin: 0;
                        &:first-child {
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                            a {
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;
                            }
                        }
                        &:last-child {
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                            a {
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                            }
                        }
                        a {
                            display: block;
                            padding: 10px;
                            color: $color-white;
                            &:hover {
                                color: $secondary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
